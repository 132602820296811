import { createStore } from "vuex";

export default createStore({
  state: {
    apiUrl: "https://student.al-turath.university/api",
    // apiUrlApplication: "http://localhost:4340/api",
    apiUrlApplication: "https://form.al-turath.university/api",
    apiUrlEmployee: "https://employee.janat-albelad.com/api",
    apiUrlFees: "https://finance.al-turath.university/api",
    sections: [
      { sectionName: "الصيدلة" },
      { sectionName: "علوم الامن السيبراني" },
      { sectionName: "علوم الحياة" },
      { sectionName: "هندسة تقنيات الحاسبات" },
      { sectionName: "علوم الادلة الجنائية" },
      { sectionName: "تقنيات التخدير" },
      { sectionName: "هندسة الطب الحياتي" },
      { sectionName: "تقنيات المختبرات الطبية" },
      { sectionName: "اللغة الانكليزية" },
      { sectionName: "القانون" },
      { sectionName: "المحاسبة" },
      { sectionName: "التصميم" },
      { sectionName: "تقنيات الاشعة" },
      { sectionName: "التربية البدنية وعلوم الرياضة" },
      { sectionName: "ادارة اعمال" },
      { sectionName: "علوم مالية ومصرفية" },
      { sectionName: "طب الاسنان" },
      { sectionName: "هندسة تقنيات التبريد والتكيف" },
      { sectionName: "هندسة تكرير النفط والغاز" },
      { sectionName: "تقنيات الهندسة الكهربائية" },
      { sectionName: "العلوم التربوية والنفسية" },
      { sectionName: "تقنيات صناعة الاسنان" },
      { sectionName: "الاعلام" },
      { sectionName: "التمريض" },
      { sectionName: "هندسة تقنيات الاجهزة طبية" },
      { sectionName: "هندسة معماري" },
      { sectionName: "علوم السياحية" },
      { sectionName: "التربية الاسلامية" },
      { sectionName: "تقنيات البصريات" },
      { sectionName: "علوم الفيزياء الطبية" },
      { sectionName: "علم النفس السريري" },
      { sectionName: "هندسة مدني" },
      { sectionName: "هندسة الليزر والالكترونيات البصرية" },
      { sectionName: "هندسة الطائرات" },
      { sectionName: "علوم الحاسبات" },
      { sectionName: "علوم الذكاء الاصطناعي" },
    ],
    sectionsData: null,
    years: null,
    stats: null,
    user: null,
    chartLabels: null,
    studentsNumber: null,
    pieData: null,
    showChart: false,
    barData: null,
    categories: null,
    acceptedTypes: [
      { acceptedName: "ابناء الهيئة التدريسية", idAcceptedType: 1 },
      { acceptedName: "ابناء حملة الشهادات العليا", idAcceptedType: 2 },
      { acceptedName: "الرياضيين", idAcceptedType: 3 },
      { acceptedName: "الاعلاميين", idAcceptedType: 4 },
      { acceptedName: "القناة العامة", idAcceptedType: 5 },
      { acceptedName: "قناة ذوي الشهداء", idAcceptedType: 6 },
      { acceptedName: "منحة مجانية", idAcceptedType: 7 },
      { acceptedName: "ذوي الاعاقة", idAcceptedType: 8 },
      { acceptedName: "الرعاية", idAcceptedType: 9 },
    ],
    studyTypes: [{ name: "صباحي" }, { name: "مسائي" }],
    genders: [{ name: "ذكر" }, { name: "انثى" }],
    educations: [
      { name: "الكرخ الاولى" },
      { name: "الكرخ الثانية" },
      { name: "الكرخ الثالثة" },
      { name: "الرصافة الاولى" },
      { name: "الرصافة الثانية" },
      { name: "الرصافة الثالثة" },
      { name: "ديالى" },
      { name: "اربيل" },
      { name: "الانبار" },
      { name: "بابل" },
      { name: "البصرة" },
      { name: "دهوك" },
      { name: "القادسية" },
      { name: "ذي قار" },
      { name: "السليمانية" },
      { name: "صلاح الدين" },
      { name: "كركوك" },
      { name: "كربلاء" },
      { name: "المثنى" },
      { name: "ميسان" },
      { name: "النجف" },
      { name: "نينوى" },
      { name: "واسط" },
      { name: "غير ذلك" },
    ],
    attempts: [{ name: "الدور الاول" }, { name: "الدور الثاني" }, { name: "الدور الثالث" }, { name: "دور تكميلي" }],
    userData: null,
    studentPay: {},
    printInformation: "",
    reportFees: "",
    studentDetails: null,
    subFeeStudentDetails: null,
  },
  getters: {},
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("employee");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
