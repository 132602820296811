<template>
  <div>
    <div class="grid formgrid">
      <div class="col-12 mb-2 lg:col-6 lg:mb-0 px-5">
        <div class="flex-auto">
          <label for="formNumber" class="font-bold block mb-2 text-direction-right">رقم الاستمارة</label>
          <InputNumber id="formNumber" :useGrouping="false" placeholder="رقم الاستمارة" v-model="formNumber" class="w-full mb-5" @keyup.enter="searchForm" />
        </div>
      </div>
    </div>
    <div v-if="showFields">
      <div class="grid formgrid">
        <div class="col-12 mb-2 lg:col-6 lg:mb-0 px-5">
          <div class="flex-auto">
            <label for="numberForm" class="font-bold block mb-2 text-direction-right">الرقم الجامعي</label>
            <InputText id="numberForm" type="text" placeholder="الرقم الجامعي" class="w-full mb-5" disabled :value="calculateCollegeNumber()" />
          </div>

          <div class="flex-auto">
            <label for="phone" class="font-bold block mb-2 text-direction-right">رقم الهاتف</label>
            <InputMask id="phone" v-model="studentPhone" mask="99999999999" placeholder="99999999999" fluid class="phone-number-mask mb-5" />
          </div>

          <div class="flex-auto">
            <label for="secretNumber" class="font-bold block mb-2 text-direction-right">الرقم الامتحاني</label>
            <InputText id="secretNumber" type="text" v-model="examNumber" placeholder="الرقم الامتحاني" class="w-full mb-5" />
          </div>

          <div class="flex-auto">
            <label for="acceptedId" class="font-bold block mb-2 text-direction-right">قناة القبول</label>
            <Select
              id="acceptedId"
              v-model="acceptedTypeId"
              checkmark
              optionValue="acceptedName"
              :options="$store.state.acceptedTypes"
              optionLabel="acceptedName"
              placeholder="قناة القبول"
              class="w-full md:w-56 mb-5"
            />
          </div>

          <div class="flex-auto">
            <label for="edu" class="font-bold block mb-2 text-direction-right">التربية</label>
            <Select id="edu" v-model="education" checkmark :options="$store.state.educations" optionValue="name" optionLabel="name" placeholder="التربية" class="w-full md:w-56 mb-5" />
          </div>

          <div class="flex-auto">
            <label for="section" class="font-bold block mb-2 text-direction-right">القسم المختار</label>
            <Select
              id="section"
              v-model="sectionName"
              checkmark
              :options="$store.state.sections"
              optionValue="sectionName"
              optionLabel="sectionName"
              placeholder="القسم المختار"
              class="w-full md:w-56 mb-5"
            />
          </div>

          <div class="flex-auto">
            <label for="school" class="font-bold block mb-2 text-direction-right">اسم المدرسة</label>
            <InputText id="school" type="text" v-model="schoolName" placeholder="اسم المدرسة" class="w-full mb-5" />
          </div>
        </div>
        <!--  -->
        <div class="col-12 mb-2 lg:col-6 lg:mb-0 px-5">
          <div class="flex-auto">
            <label for="name" class="font-bold block mb-2 text-direction-right">اسم الطالب</label>
            <InputText id="name" type="text" v-model="studentName" placeholder="اسم الطالب" class="w-full mb-5" />
          </div>

          <div class="flex-auto">
            <label for="phone2" class="font-bold block mb-2 text-direction-right">رقم الهاتف الثاني</label>
            <InputMask id="phone2" v-model="relativePhoneNumber" mask="99999999999" placeholder="99999999999" fluid class="phone-number-mask mb-5" />
          </div>

          <div class="flex-auto">
            <label for="secretNumber" class="font-bold block mb-2 text-direction-right">الرقم السري</label>
            <InputText id="secretNumber" type="text" v-model="password" placeholder="الرقم السري" class="w-full mb-5" />
          </div>

          <div class="flex-auto">
            <label for="dob" class="font-bold block mb-2 text-direction-right">تاريخ الميلاد</label>
            <InputMask id="dob" v-model="dob" @keydown="checkDOB" placeholder="99/99/9999" mask="99/99/9999" slotChar="dd/mm/yyyy" class="w-full mb-5 phone-number-mask" />
          </div>

          <div class="flex-auto">
            <label for="cat" class="font-bold block mb-2 text-direction-right">الفرع الدراسي</label>
            <Select
              id="cat"
              v-model="categoryId"
              checkmark
              :options="$store.state.categories"
              optionLabel="categoryName"
              optionValue="idCategory"
              placeholder="الفرع الدراسي"
              class="w-full md:w-56 mb-5"
            />
          </div>

          <div class="flex-auto">
            <label for="gender" class="font-bold block mb-2 text-direction-right">الجنس</label>
            <Select id="gender" v-model="gender" checkmark :options="$store.state.genders" optionLabel="name" optionValue="name" placeholder="الجنس" class="w-full md:w-56 mb-5" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFields">
      <div class="grid formgrid">
        <div class="col-12 mb-12 lg:col-12 lg:mb-0 px-5">
          <div class="flex-auto">
            <label for="cat" class="font-bold block mb-2 text-direction-right">الملاحظات</label>
            <Textarea v-model="notes" rows="5" cols="30" class="w-full md:w-56 mb-5" />
          </div>
        </div>
      </div>

      <div style="height: 20px"></div>
      <div class="grid formgrid">
        <div class="col-12 mb-12 lg:col-12 lg:mb-0 px-5">
          <Divider align="center" type="solid">
            <b>رفع الملفات</b>
          </Divider>
        </div>
      </div>

      <div style="height: 20px"></div>
      <div style="text-align: right; padding-right: 20px">
        <Button type="button" label="تحويل الصور الى PDF" icon="pi pi-file-pdf" @click="openPDF" severity="help" />
      </div>
      <div style="height: 20px"></div>

      <div class="grid formgrid">
        <div class="col-12 mb-6 lg:col-6 lg:mb-0 px-5">
          <div class="card">
            <Toast />
            <FileUpload
              name="attachment"
              :showCancelButton="false"
              :url="`${$store.state.apiUrlApplication}/upload`"
              @upload="onAdvancedUpload($event)"
              @error="onError($event)"
              accept="application/pdf"
              :maxFileSize="5000000"
              chooseLabel="اختيار الملف"
              uploadLabel="رفع الملف"
            >
              <template #empty>
                <h5>رفع الهوية الشخصية للطالب على ان تكون بصيغة PDF</h5>
              </template>
            </FileUpload>
          </div>
        </div>

        <div class="col-12 mb-6 lg:col-6 lg:mb-0 px-5">
          <div class="card">
            <Toast />
            <FileUpload
              name="attachment"
              :showCancelButton="false"
              :url="`${$store.state.apiUrlApplication}/upload`"
              @upload="onAdvancedUploadSecond($event)"
              @error="onError($event)"
              accept="application/pdf"
              :maxFileSize="5000000"
              chooseLabel="اختيار الملف"
              uploadLabel="رفع الملف"
            >
              <template #empty>
                <h5>رفع تأييد الدرجات على ان تكون بصيغة PDF</h5>
              </template>
            </FileUpload>
          </div>
        </div>
      </div>
      <div class="grid formgrid">
        <div class="col-12 mb-12 lg:col-12 lg:mb-0 px-5">
          <Divider align="center" type="solid"> </Divider>
        </div>
      </div>

      <div class="justify-center">
        <Button type="button" label="حفظ" icon="pi pi-check" :loading="loading" @click="saveStudent" />
      </div>
    </div>
  </div>
  <Toast position="top-center" group="tl" />
</template>

<script>
import InputMask from "primevue/inputmask";
import Toast from "primevue/toast";
import AutoComplete from "primevue/autocomplete";
import Textarea from "primevue/textarea";
import Divider from "primevue/divider";
import FileUpload from "primevue/fileupload";

export default {
  components: {
    Toast,
    AutoComplete,
    InputMask,
    Textarea,
    Divider,
    FileUpload,
  },

  data: () => ({
    showFields: false,
    formNumber: null,
    studentName: "",
    studentPhone: "",
    examNumber: "",
    password: "",
    loading: false,
    relativePhoneNumber: "",
    acceptedTypeId: "",
    value: "",
    searchAccepted: "",
    dob: "13/02/1991",
    education: "",
    categoryId: "",
    sectionName: "",
    gender: "",
    uploadFile: "none",
    uploadSecondFile: "none",
    schoolName: "",
    notes: "لايوجد",
  }),
  methods: {
    checkDOB() {
      let self = this;
      console.log(self.dob.split("/")[1]);
      if (self.dob.split("/")[1] > 12) {
        self.dob = `${self.dob.split("/")[0]}/12/${self.dob.split("/")[2]}`;
      }
    },
    onAdvancedUpload(event) {
      console.log(JSON.parse(event.xhr.response).imagePath);
      this.uploadFile = JSON.parse(event.xhr.response).imagePath;
      this.$toast.add({ severity: "success", summary: "رفع الملف", detail: "تم رفع الملف بنجاح", life: 3000, group: "tl" });
    },
    onAdvancedUploadSecond(event) {
      console.log(JSON.parse(event.xhr.response).imagePath);
      this.uploadSecondFile = JSON.parse(event.xhr.response).imagePath;
      this.$toast.add({ severity: "success", summary: "رفع الملف", detail: "تم رفع الملف بنجاح", life: 3000, group: "tl" });
    },
    onError(event) {
      console.log(event);
      this.$toast.add({ severity: "error", summary: "خطأ", detail: "حدث خطأ اثناء رفع الملف", life: 3000, group: "tl" });
    },
    calculateCollegeNumber() {
      let self = this;
      let acceptedYear = "01";
      let gender = self.gender == "ذكر" ? 0 : 1;
      let dob = self.dob.slice(8, 10);
      let randomNumber = Math.floor(Math.random() * (120 - 10 + 1)) + 50;
      return `2414${acceptedYear}${Math.floor(Math.random() * 40)}${dob}${gender}${randomNumber}`;
    },
    isNumber(value) {
      return typeof value === "number";
    },
    openPDF() {
      window.open("https://www.ilovepdf.com/jpg_to_pdf", "_blank").focus();
    },
    search(event) {
      console.log(event);
    },

    saveStudent() {
      let self = this;
      self.loading = true;

      let data2 = {
        applicationNumber: self.formNumber,
        studentName: self.studentName,
        studentPhone: self.studentPhone,
        collegeNumber: self.calculateCollegeNumber(),
        relativePhoneNumber: self.relativePhoneNumber,
        examNumber: self.examNumber,
        password: self.password,
        dob: self.dob.split("/")[1] > 12 ? `${self.dob.split("/")[0]}/12/${self.dob.split("/")[2]}` : `${self.dob.split("/")[0]}/${self.dob.split("/")[1]}/${self.dob.split("/")[2]}`,
        categoryId: self.categoryId,
        acceptedTypeId: self.acceptedTypeId,
        gender: self.gender,
        notes: self.notes,
        education: self.education,
        schoolName: self.schoolName,
        sectionName: self.sectionName,
        filePath: self.uploadFile,
        secondFile: self.uploadSecondFile,
        createdBy: self.$store.state.userData.idEmployee,
      };
      console.log(data2);
      if (
        self.studentName &&
        self.studentPhone &&
        self.examNumber &&
        self.password &&
        self.categoryId &&
        self.acceptedTypeId &&
        self.gender &&
        self.education &&
        self.schoolName &&
        self.notes &&
        self.relativePhoneNumber &&
        self.sectionName
      ) {
        let data = {
          applicationNumber: self.formNumber,
          studentName: self.studentName,
          studentPhone: self.studentPhone,
          collegeNumber: self.calculateCollegeNumber(),
          relativePhoneNumber: self.relativePhoneNumber,
          examNumber: self.examNumber,
          password: self.password,
          dob: self.dob,
          categoryId: self.categoryId,
          acceptedTypeId: self.acceptedTypeId,
          gender: self.gender,
          notes: self.notes,
          education: self.education,
          schoolName: self.schoolName,
          sectionName: self.sectionName,
          filePath: self.uploadFile,
          secondFile: self.uploadSecondFile,
          createdBy: self.$store.state.userData.idEmployee,
        };
        self.$http
          .post(`${self.$store.state.apiUrlApplication}/addApplication`, data, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((resStudent) => {
            this.$toast.add({ severity: "success", summary: "تم الرفع", detail: "تم اضافة الطالب", group: "tl", life: 3000 });
            console.log(resStudent.data);
            self.studentName = "";
            self.studentPhone = "";
            self.examNumber = null;
            self.password = "";
            self.categoryId = "";
            self.acceptedTypeId = "";
            self.gender = "";
            self.notes = "لايوجد";
            self.relativePhoneNumber = "";
            self.education = "";
            self.schoolName = "";
            self.formNumber = "";
            self.uploadFile = "none";
            self.uploadSecondFile = "none";
            self.loading = false;
            self.showFields = false;
          })
          .catch((e) => {
            console.log(e);
            self.loading = false;
            this.$toast.add({ severity: "error", summary: "خطأ", detail: "حدث خطأ الرجاء التأكد من المعلومات", group: "tl", life: 3000 });
          });
      } else {
        self.loading = false;
        this.$toast.add({ severity: "error", summary: "خطأ", detail: " الرجاء ملئ الحقول المطلوبة", group: "tl", life: 3000 });
      }
    },
    searchForm() {
      let self = this;
      self.showFields = false;
      if (parseInt(self.formNumber)) {
        if (self.isNumber(parseInt(self.formNumber))) {
          self.$http
            .get(`${self.$store.state.apiUrlFees}/searchByNumber/${self.formNumber}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
            .then((res) => {
              self.$http
                .get(`${self.$store.state.apiUrlApplication}/applicationSearch/${self.formNumber}`, {
                  headers: { Authorization: `Bearer ${self.$store.state.user}` },
                })
                .then((resFound) => {
                  self.showFields = false;

                  this.$toast.add({ severity: "error", summary: "خطأ", detail: `هذه الاستمارة محجوزة باسم الطالب ${resFound.data.studentName}`, group: "tl", life: 3000 });
                })
                .catch((err) => {
                  self.showFields = true;
                });
            })
            .catch((e) => {
              this.$toast.add({ severity: "error", summary: "خطأ", detail: "هذه الاستمارة غير مباعه", group: "tl", life: 3000 });
              self.showFields = false;
              //   self.overlay = false;
            });
        } else {
          //   self.$toasted.error("الرجاء ادخال رقم الاستمارة باللغة الانكليزية");
        }
      } else {
        // self.$toasted.error("الرجاء ادخال رقم الاستمارة");
        this.$toast.add({ severity: "error", summary: "خطأ", detail: "الرجاء ادخال رقم الاستمارة", group: "tl", life: 3000 });
        // self.overlay = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-number-mask {
  direction: ltr !important;
}

.text-direction-right {
  text-align: right !important;
}
</style>
