import { createMemoryHistory, createRouter } from "vue-router";

import HomeView from "./../pages/Home.vue";
import Application from "./../pages/Application.vue";
import Report from "./../pages/Report.vue";
import Login from "./../pages/Login.vue";
import Confirm from "./../pages/Confirm.vue";
import AddStudent from "./../components/applications/AddStudent.vue";
import EditApplication from "./../components/applications/EditApplication.vue";
import SearchApplication from "./../components/applications/SearchApplication.vue";

const routes = [
  { path: "/", component: HomeView },
  { path: "/application", component: Application },
  { path: "/report", component: Report },
  { path: "/login", component: Login },
  { path: "/add", component: AddStudent },
  { path: "/edit", component: EditApplication },
  { path: "/search", component: SearchApplication },
  { path: "/confirm", component: Confirm },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

export default router;
