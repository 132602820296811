<template>
  <div style="margin-top: 30px">
    <div class="card mx-6">
      <div class="flex justify-content-right">
        <Button label="بحث" icon="pi pi-search" @click="visible = true" />
        <div style="margin: 0 auto"></div>

        <!-- <download-excel v-if="items.length > 0" :data="items" type="xlsx" worksheet="تقرير الطلبة" name="تقرير الطلبة.xlsx" :rtl="true" :fields="json_fields">
          <Button label="تصدير" icon="pi pi-file-excel" severity="help" />
        </download-excel> -->
        <Button label="تصدير" icon="pi pi-file-excel" severity="help" v-if="items.length > 0" @click="printReport" />
      </div>

      <div style="height: 30px"></div>

      <!-- <DataTable :value="items" showGridlines tableStyle="min-width: 50rem" :loading="loading">
        <Column field="studentName" header="اسم الطالب"></Column>
        <Column field="collegeNumber" header="الرقم الجامعي"></Column>
        <Column field="acceptedTypeId" header="قناة القبول"></Column>
        <Column field="sectionName" header="القسم"></Column>
      </DataTable> -->
    </div>
  </div>

  <table border="1" v-if="items.length > 0" style="margin: auto">
    <thead>
      <tr>
        <td>القسم</td>
        <td>المثبتين لقناة الشهداء الصباحي</td>
        <td>المثبتين لقناة الشهداء المسائي</td>
        <td>المثبتين للقناة العامة الصباحي</td>
        <td>المثبتين للقناة العامة المسائي</td>
        <td>المثبتين لقناة المنحة المجانية الصباحي</td>
        <td>المثبتين لقناة المنحة المجانية المسائي</td>
        <td>المثبتين لقناة الرعاية الصباحي</td>
        <td>المثبتين لقناة الرعاية المسائي</td>
        <td>المجموع الكلي</td>
      </tr>
    </thead>
    <tr v-for="(section, index) in $store.state.sectionsData" :key="index">
      <td>{{ section.sectionName }}</td>
      <td>{{ getByAcceptedType(5, section.idSection, "1") }}</td>
      <td>{{ getByAcceptedType(5, section.idSection, "2") }}</td>
      <td>{{ getByAcceptedType(4, section.idSection, "1") }}</td>
      <td>{{ getByAcceptedType(4, section.idSection, "2") }}</td>
      <td>{{ getByAcceptedType(7, section.idSection, "1") }}</td>
      <td>{{ getByAcceptedType(7, section.idSection, "2") }}</td>
      <td>{{ getByAcceptedType(66, section.idSection, "1") }}</td>
      <td>{{ getByAcceptedType(66, section.idSection, "2") }}</td>
      <td>{{ getMorning(section.idSection, "1") + getMorning(section.idSection, "2") }}</td>
    </tr>
  </table>
  <p>مجموع الطلبة : {{ totalStudy("1") + totalStudy("2") }}</p>

  <Dialog v-model:visible="visible" header="بحث في الطلبة" :style="{ width: '55rem' }" position="top" :modal="true" :draggable="false" style="direction: rtl">
    <div class="flex-auto">
      <label for="section" class="font-bold block mb-2 text-direction-right">القسم المختار</label>
      <Select id="section" v-model="generatedType" checkmark showClear :options="generatedTypes" optionLabel="name" optionValue="id" placeholder="نوع التقرير" class="w-full md:w-56 mb-5" />
    </div>

    <div class="flex justify-end gap-2">
      <Button type="button" label="بحث" @click="searchStudent" :loading="loading"></Button>
    </div>
  </Dialog>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data: () => ({
    visible: false,
    sectionName: null,
    acceptedTypeId: null,
    loading: false,
    items: [],
    generatedType: 1,
    generatedTypes: [
      { id: 1, name: "بدون مدفوعات" },
      { id: 2, name: " مدفوعات" },
    ],
    dates: null,
    json_fields: {
      "اسم الطالب": "studentName",
      "الرقم الجامعي": "collegeNumber",
      "الرقم الامتحاني": "examNumber",
      المواليد: "dob",
      "قناة القبول": "acceptedTypeId",
      "جنس الطالب": "gender",
      القسم: "sectionName",
      "اسم المدرسة": "schoolName",
      الملاحظات: "notes",
      التربية: "education",
      "رقم الهاتف": "studentPhone",
      "رقم الهاتف الثاني": "relativePhoneNumber",
    },
  }),
  methods: {
    convertToMySQLDate(inputDate) {
      // Split the input date by '/' to extract day, month, and year
      const [month, day, year] = inputDate.split("/").map(Number);

      // Ensure day and month are two digits by adding a leading zero if necessary
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      // Combine the parts into the MySQL date format
      const mysqlDate = `${year}-${formattedMonth}-${formattedDay}`;

      return mysqlDate;
    },
    exportExcel() {},
    totalStudy(type) {
      let self = this;
      let data = self.items.filter((stu) => stu.studyType == type);
      return data.length;
    },
    getByAcceptedType(type, sectionId, studyType) {
      let self = this;
      let data = self.items.filter((stu) => stu.acceptedTypeId == type && stu.sectionId == sectionId && stu.studyType == studyType);
      console.log(data);
      return data.length;
    },
    getMorning(sectionId, type) {
      let self = this;
      let data = self.items.filter((stu) => stu.sectionId == sectionId && stu.studyType == type);
      console.log(data);
      return data.length;
    },
    searchStudent() {
      let self = this;
      self.loading = true;

      if (self.generatedType == 1) {
        let query = "1=1";
        if (self.sectionName) {
          query += `&sectionName=${self.sectionName}`;
        }
        if (self.acceptedTypeId) {
          query += `&acceptedTypeId=${self.acceptedTypeId}`;
        }

        if (self.dates != null) {
          let firstDate = new Date(self.dates[0]).toLocaleDateString("en-US", { year: "numeric", month: "numeric", day: "numeric" });
          let secondDate = new Date(self.dates[1]).toLocaleDateString("en-US", { year: "numeric", month: "numeric", day: "numeric" });
          let allowDates = [self.convertToMySQLDate(firstDate), self.convertToMySQLDate(secondDate)];
          query += `&dates=${JSON.stringify(allowDates)}`;
        }

        self.$http
          .get(`${self.$store.state.apiUrl}/getStudents?studentStatusId=4`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(res.data);
            self.loading = false;
            self.items = res.data;
          })
          .catch((err) => {
            console.log(err);
            self.loading = false;
          });
        self.visible = false;
      } else {
        self.$http
          .get(`${self.$store.state.apiUrl}/studentPayOne`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            console.log(res.data);
            self.loading = false;
            self.items = res.data;
          })
          .catch((err) => {
            console.log(err);
            self.loading = false;
          });
        self.visible = false;
      }
    },
    printReport() {
      let self = this;
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
      });

      let arabicLang = new URL(`./../assets/IBMPlexSansArabic-Medium.ttf`, import.meta.url).href;
      doc.addFileToVFS("IBMPlexSansArabic-Medium.ttf", arabicLang);
      doc.addFont(arabicLang, "IBMPlexSansArabic-Medium", "normal");
      doc.setFont("IBMPlexSansArabic-Medium");

      if (self.reportTypeId == 1) {
        const tableData = [["الملاحظات", "الصندوق", "تبويب الايراد", "نوع الايراد", "المبلغ", "تاريخ الايراد", "ت"]];

        let rows = self.searchData.map((rep, index) => {
          return [rep.description, rep.boxName, rep.typeName, rep.isExternal == 1 ? "خارجي" : "داخلي", rep.amount.toLocaleString("en-US"), rep.dateFormat, index + 1];
        });
        doc.autoTable({
          head: [tableData[0]],
          body: rows,
          styles: {
            font: "IBMPlexSansArabic-Medium",
            halign: "right",
            fontSize: 7,
          }, // Align text to right
          headStyles: { fillColor: "#723d46" }, // Optional styling for header
          margin: { top: 0, left: 0, right: 0, bottom: 0 },
        });

        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const pdfWindow = window.open(pdfUrl);
      } else if (self.reportTypeId == 2) {
        const tableData = [["الملاحظات", "الصندوق", "التبويب", "تبويب العناصر", "الجهة المستفيدة الثانوية", "الجهة المستفيدة", "اسم المستلم", "المدين", "سداد دين ؟", "المبلغ", "تاريخ الصرف"]];

        let rows = self.searchData.map((rep, index) => {
          return [
            rep.notes,
            rep.boxName,
            rep.tabName,
            rep.itemTabName,
            rep.subName,
            rep.beneficiaryName,
            rep.receiverName ? rep.receiverName : "لايوجد",
            rep.debtorId != null ? self.debtors.find((deb) => deb.idDebtor == rep.debtorId).debtorName : "لايوجد",
            rep.isDebt == 1 ? "نعم" : "كلا",
            rep.amount.toLocaleString("en-US"),
            rep.dateFormat,
          ];
        });
        doc.autoTable({
          // theme: "grid",
          columns: [
            { dataKey: "id", header: "الملاحظات" },
            { dataKey: "box", header: "الصندوق" },
            { dataKey: "subtype", header: "التبويب" },
            { dataKey: "type", header: "تبويب العناصر" },
            { dataKey: "sub", header: "الجهة المستفيدة الثانوية" },
            { dataKey: "bene", header: "الجهة المستفيدة" },
            { dataKey: "name", header: "اسم المستلم" },
            { dataKey: "debtor", header: "المدين" },
            { dataKey: "debt", header: "سداد دين ؟" },
            { dataKey: "amount", header: "المبلغ" },
            { dataKey: "date", header: "تاريخ الصرف" },
          ],
          body: rows,
          columnStyles: {
            id: {
              cellWidth: 100,
              halign: "right",
            },
            debt: {
              cellWidth: 20,
            },
            amount: {
              cellWidth: 30,
            },
            date: {
              cellWidth: 30,
            },
            subtype: { cellWidth: 40 },
            box: { cellWidth: 40 },
            type: { cellWidth: 40 },
            bene: { cellWidth: 40 },
            sub: { cellWidth: 40 },

            debtor: { cellWidth: 30 },
          },
          styles: {
            overflow: "linebreak",
            font: "IBMPlexSansArabic-Medium",
            halign: "right",
            fontSize: 5,
          }, // Align text to right
          headStyles: { fillColor: "#723d46" }, // Optional styling for header
          margin: { top: 0, left: 0, right: 0, bottom: 0 },
        });

        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const pdfWindow = window.open(pdfUrl);
      }

      //   doc.save(`${today.toLocaleDateString("en-GB")}.pdf`);

      // const pdfBlob = doc.output("blob");
      // const pdfUrl = URL.createObjectURL(pdfBlob);
      // const pdfWindow = window.open(pdfUrl);

      // Trigger the print dialog when the PDF is loaded in the new tab
      // if (pdfWindow) {
      //   pdfWindow.onload = () => {
      //     pdfWindow.print();
      //   };
      // }
    },
  },
};
</script>

<style lang="scss" scoped></style>
